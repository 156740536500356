<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-12 px-0">
              <div class="col-3 pr-0">
                <vs-select
                  v-if="diners"
                  v-model="dinerSearch"
                  :options="diners"
                  :placeholder="
                    $t('mealsystem.orders.placeholder.search_diners')
                  "
                  label="last_name"
                  :reduce="(diners) => diners.diner_id"
                />
              </div>
              <div class="col-3 pr-0">
                <div class="input-group input-group-lg input-group-solid">
                  <date-picker
                    v-model="dateSearch"
                    range
                    :placeholder="
                      $t('mealsystem.orders.placeholder.date_search')
                    "
                    format="DD.MM.YYYY"
                    value-type="YYYY-MM-DD"
                  ></date-picker>
                </div>
              </div>

              <b-button
                variant="secondary"
                class="mr-3 ml-3 pr-2"
                @click.prevent="removeFilters"
              >
                <i class="fa fa-times icon-md"></i>
              </b-button>
              <b-button variant="primary" @click.prevent="searchOrders">
                {{ $t("button.filter") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom">
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center"
          >
            <thead>
              <tr class="text-left text-uppercase">
         
  
                <!-- TODO -->


                <th width="150">
                  {{ $t("mealsystem.orders.label.created_at") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading">
              <tr
                v-for="(order, index) in orders.data"
                :key="index"
              >
     
                <!-- TODO -->


                <td>
                  <span>{{
                    order.created_at | moment("D. M. YYYY HH:MM")
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination v-if="orders.meta" :data="orders.meta"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      loading: false,
      page: 1,
      search: "",
      orders: null,
      diners: null,
      dinerSearch: null,
      dateSearch: null,
    };
  },
  components: {
    Pagination
  },
  beforeMount() {
    this.getOrders();
    this.getDiners();
  },
    metaInfo () { 
      return { title: this.$t("mealsystem.meta.diners_orders")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("mealsystem.meta.diners"), subtitle: this.$t("mealsystem.meta.diners_orders") }]);
  },
  computed: {
    perpage() {
      return this.$store.state.pagination.pagination.itemsPerPage;
    },
  },
  methods: {
    getOrders() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/diners/orders?page=" +
          this.page +
          "&perpage=" +
          this.perpage +
          "&diner_id=" +
          this.dinerSearch +
          "&date=" +
          this.dateSearch
      ).then((response) => {
        this.orders = response.data;
        this.loading = false;
      });
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getOrders();
    },
    searchOrders() {
      this.page = 1;
      this.getOrders();
    },
    removeFilters() {
      this.page = 1;
      this.dinerSearch = null;
      this.dateSearch = "";
      this.getOrders();
    },
    getDiners() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/diners?departments=null&categories=null"
      ).then((response) => {
        this.diners = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
